<template>
  <router-view/>
</template>

<style lang="scss">

@import "styles/helpers/_reset.scss";
@import "styles/helpers/_typo.scss";
@import "styles/helpers/_util.scss";
@import "styles/helpers/_variables.scss";

</style>